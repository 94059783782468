import React from 'react'
import PropTypes from 'prop-types'
import withWidth from '@material-ui/core/withWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'

import {ReactComponent as LogoSvg} from '../assets/images/logos/logo.svg'
import {ReactComponent as BlotchSvg} from '../assets/images/figures/blotch.svg'
import {ReactComponent as ArrowIcon} from '../assets/images/icons/arrow.svg'
import melissaImage from '../assets/images/blocks/melissa.png'
import melissaImage2x from '../assets/images/blocks/melissa@2x.png'
import melissaMobileImage from '../assets/images/blocks/melissa_mobile.png'
import melissaMobileImage2x from '../assets/images/blocks/melissa_mobile@2x.png'
import SubscriptionButtons from './_common/SubscriptionButtons'
import FirstButton from './_common/FirstButton'
import ScrollAnimation from './_common/ScrollAnimation'
import Drawer from './Drawer'
import headerLinks from '../data/headerLinks'
import './Header.scss'

const StyledGrid = withStyles({
  root: {
    height: '100%',
  },
})(Grid)

const renderedLinks = headerLinks.map((link, index) => {
  const Link = index === 1 ? Button : FirstButton
  return (
    <Link
      key={link.id}
      variant={index === 1 ? 'contained' : null}
      component="a"
      href={link.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.name}
    </Link>
  )
})

Header.propTypes = {
  width: PropTypes.string.isRequired,
}

function Header({width}) {
  const isMobile = width === 'xs'
  return (
    <header className="Header">
      <div className="Header-Container Container">
        <h1 className="VisuallyHidden">Melissa Chalmers</h1>
        <ScrollAnimation isEaseOut className="Header-Blotch" effect="scaleOut" duration={4000}>
          <BlotchSvg />
        </ScrollAnimation>
        <ScrollAnimation className="Header-Melissa" effect="fadeInRight" delay={250} duration={2000}>
          <img
            width={isMobile ? 234 : 677}
            height={isMobile ? 678 : 661}
            src={isMobile ? melissaMobileImage : melissaImage}
            srcSet={`${isMobile ? melissaMobileImage2x : melissaImage2x} 2x`}
            alt="Melissa Chalmers"
          />
        </ScrollAnimation>
        <StyledGrid container spacing={2}>
          <Hidden mdDown>
            <Grid item lg={1} />
          </Hidden>
          <Grid item xs={12} lg={11}>
            <div className="Header-Content">
              <div className="Header-Panel">
                <ScrollAnimation effect="fadeInLeft" delay={500}>
                  <LogoSvg className="Header-Logo" />
                </ScrollAnimation>
                {isMobile ? (
                  <Drawer />
                ) : (
                  <ScrollAnimation className="Header-Links" effect="fadeInRight" delay={500}>
                    {renderedLinks}
                  </ScrollAnimation>
                )}
              </div>
              <div className="Header-Block">
                <div>
                  <p className="Header-Attention">
                    <b>
                      <ScrollAnimation tag="span" effect={isMobile ? 'fadeInLeft' : 'fadeInTop'} delay={250}>
                        Be
                      </ScrollAnimation>
                      <br />
                      {isMobile ? (
                        <>
                          <ScrollAnimation tag="span" effect="fadeInRight" delay={400}>
                            Str
                          </ScrollAnimation>
                          <br />
                          <ScrollAnimation tag="span" effect="fadeInLeft" delay={500}>
                            ong
                          </ScrollAnimation>
                          <br />
                        </>
                      ) : (
                        <ScrollAnimation tag="span" effect="fadeInBottom" delay={500}>
                          Strong
                        </ScrollAnimation>
                      )}
                      <ScrollAnimation key={isMobile} className="Header-AttentionPart" tag="span" effect="fadeInRight" delay={750}>
                        Her
                      </ScrollAnimation>
                    </b>
                  </p>
                  <ScrollAnimation className="Header-Text" effect="fadeInBottom" delay={1000}>
                    Feel confident mentally and physically
                  </ScrollAnimation>
                  <ScrollAnimation className="Header-Subscription" effect="fadeInBottom" delay={1250}>
                    <SubscriptionButtons />
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </Grid>
        </StyledGrid>
        <div className="Header-Scroll TranslateDownUp">
          <ArrowIcon />
        </div>
      </div>
    </header>
  )
}

export default withWidth()(Header)
