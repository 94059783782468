import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import MuiDrawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import {ReactComponent as MenuIcon} from '../assets/images/icons/menu.svg'
import headerLinks from '../data/headerLinks'
import {COLORS} from '../styles/muiTheme'

const StyledIconButton = withStyles({
  root: {
    width: 52,
    height: 52,
    color: COLORS.CHARCOAL_GREY
  }
})(IconButton)

const renderedLinks = headerLinks.map(link => (
  <ListItem key={link.id} button component="a" href={link.href} target="_blank" rel="noopener noreferrer">
    <ListItemText primary={link.name} />
  </ListItem>
))

export default class Drawer extends Component {
  state = {
    isOpen: false,
  }

  handleDrawerToggle = () => this.setState(state => ({
    isOpen: !state.isOpen
  }))

  render() {
    const {isOpen} = this.state
    return (
      <>
        <StyledIconButton onClick={this.handleDrawerToggle}>
          <MenuIcon />
        </StyledIconButton>
        <MuiDrawer anchor="right" open={isOpen} onClose={this.handleDrawerToggle}>
          <List>
            {renderedLinks}
          </List>
        </MuiDrawer>
      </>
    )
  }
}
