import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import {ReactComponent as ConfidenceIcon} from '../assets/images/icons/confidence.svg'
import {ReactComponent as HappierIcon} from '../assets/images/icons/happier.svg'
import {ReactComponent as HealthIcon} from '../assets/images/icons/health.svg'
import {ReactComponent as GreatIcon} from '../assets/images/icons/great.svg'
import SubscriptionButtons from './_common/SubscriptionButtons'
import ScrollAnimation from './_common/ScrollAnimation'
import './Benefits.scss'

const benefits = [
  {
    id: 1,
    name: 'Confidence',
    Icon: ConfidenceIcon,
    text: 'A regular workout routine can have a huge impact on your self-confidence',
  },
  {
    id: 2,
    name: 'Happier',
    Icon: HappierIcon,
    text: 'Small amounts of exercise can have an outsize effect on happiness and overall wellbeing',
  },
  {
    id: 3,
    name: 'Mental Health',
    Icon: HealthIcon,
    text: 'Exercise is proven to improve your mood by releasing feel good chemicals in your brain',
  },
  {
    id: 4,
    name: 'Look great',
    Icon: GreatIcon,
    text: 'Tone and Tighten your body and feel the amazing benefits of shaping up',
  },
]

const renderedBenefits = benefits.map((benefit, index) => (
  <ScrollAnimation key={benefit.id} tag="li" className="Benefits-Item" effect="fadeInBottomSmall" delay={index * 150}>
    <benefit.Icon className="Benefits-ItemIcon" />
    <h3 className="Benefits-ItemTitle">{benefit.name}</h3>
    <p className="Benefits-ItemText">{benefit.text}</p>
  </ScrollAnimation>
))

export default function Benefits() {
  return (
    <section className="Benefits Container">
      <h2 className="Benefits-Title Title">
        StrongHer
        <br />
        Workout Benefits
      </h2>
      <Grid container spacing={2}>
        <Hidden mdDown>
          <Grid item lg={1} />
        </Hidden>
        <Grid item xs={12} lg={10}>
          <ul className="Benefits-List">{renderedBenefits}</ul>
          <ScrollAnimation effect="fadeInBottom">
            <SubscriptionButtons />
          </ScrollAnimation>
        </Grid>
        <Hidden mdDown>
          <Grid item lg={1} />
        </Hidden>
      </Grid>
    </section>
  )
}
