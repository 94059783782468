import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import ScrollAnimation from './_common/ScrollAnimation'
import './Workout.scss'

export default function Workout() {
  return (
    <section className="Workout">
      <div className="Workout-Container Container">
        <h2 className="Title">Workout program</h2>
        <ScrollAnimation tag="p" className="Workout-Attention Attention" effect="fadeInBottom">
          <b>Start my program today!</b>
        </ScrollAnimation>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item lg={1} />
          </Hidden>
          <Grid item xs={12} lg={10}>
            <div className="Workout-Types">
              <ScrollAnimation className="Workout-Type Workout-Type_first" effect="fadeInLeft">
                <div className="Workout-TypeContent">
                  <h3 className="Workout-TypeTitle">At<br />Home </h3>
                  <p className="Workout-TypeSubtitle">Program</p>
                  <p className="Workout-TypeText">Workout with me as your trainer at home </p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation className="Workout-Type Workout-Type_second" effect="fadeInRight">
                <div className="Workout-TypeContent">
                  <h3 className="Workout-TypeTitle">Gym </h3>
                  <p className="Workout-TypeSubtitle">Program</p>
                  <p className="Workout-TypeText">Workout with me as your trainer at the gym  </p>
                </div>
              </ScrollAnimation>
            </div>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={1} />
          </Hidden>
        </Grid>
      </div>
    </section>
  )
}
