import React from 'react'
import ReactDOM from 'react-dom'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import {createMuiTheme} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import TagManager from 'react-gtm-module'

import './styles/index.scss'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import muiTheme from './styles/muiTheme'

const tagManagerArgs = {
  gtmId: 'GTM-TP2M4B9'
}

TagManager.initialize(tagManagerArgs)

const theme = createMuiTheme(muiTheme)

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
