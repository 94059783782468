import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import ScrollAnimation from './_common/ScrollAnimation'
import links from '../data/social'
import './About.scss'

const renderedLinks = links.map((link, index) => (
  <ScrollAnimation key={link.id} tag="li" className="About-Item" effect="fadeInBottom" delay={index * 150}>
    <a className="About-Link" href={link.href} target="_blank" rel="noopener noreferrer">
      <link.Icon />
      <span className="About-LinkName">{link.name}</span>
    </a>
  </ScrollAnimation>
))

export default function About() {
  return (
    <section className="About Container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <div className="About-Block" />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <h2 className="Title">About</h2>
          <ScrollAnimation tag="p" className="Attention" effect="fadeInLeft">
            <b>Melissa Chalmers</b>
          </ScrollAnimation>
          <ScrollAnimation tag="p" className="About-Text" effect="fadeInRight">
            I have helped thousands of women transform their bodies in the healthiest way possible. I believe in healthy
            living through exercise and nutrition, and diets don’t exist in my vocabulary. Good quality nutrition and
            non-restrictive foods in moderation is the key to maintaining a healthy looking body while still{' '}
            <span className="About-Word"><span>enjoing life.</span></span> Join our intimate group of female community!
          </ScrollAnimation>
          <ul className="About-List">{renderedLinks}</ul>
        </Grid>
        <Hidden mdDown>
          <Grid item lg={1} />
        </Hidden>
      </Grid>
    </section>
  )
}
