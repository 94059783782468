export const COLORS = {
  CHARCOAL_GREY_LIGHT: '#515b6a',
  CHARCOAL_GREY: '#3b4554',
  CHARCOAL_GREY_DARK: '#283240',
  LIGHT_PEACH: '#ffbdb5',
  BACKGROUND: '#fbfbfb',
}

export default {
  typography: {
    fontFamily: '"Boston", sans-serif',
    color: 'black',
    fontSize: 16,
  },
  palette: {
    primary: {
      light: COLORS.CHARCOAL_GREY_LIGHT,
      main: COLORS.CHARCOAL_GREY,
      dark: COLORS.CHARCOAL_GREY_DARK,
    },
    secondary: {
      light: COLORS.LIGHT_PEACH,
      main: COLORS.LIGHT_PEACH,
      dark: COLORS.LIGHT_PEACH,
    },
    background: {
      default: COLORS.BACKGROUND,
    },
    text: {
      primary: COLORS.CHARCOAL_GREY,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 24,
        minHeight: 48,
        fontSize: 14,
        fontWeight: 900,
        paddingLeft: 32,
        paddingRight: 32,
        '@media (max-width: 959px)': {
          fontSize: 10,
          minHeight: 34,
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      contained: {
        backgroundColor: 'white',
        boxShadow:
          '1px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      },
      label: {
        position: 'relative',
        top: 2,
        '@media (max-width: 959px)': {
          top: 0,
        },
      },
      text: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
}
