import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Screens.scss'

export default class Screens extends Component {
  state = {
    activeScreen: 1,
  }
  timerId = null

  static propTypes = {
    title: PropTypes.string.isRequired,
    firstImage: PropTypes.string.isRequired,
    firstImage2x: PropTypes.string.isRequired,
    secondImage: PropTypes.string.isRequired,
    secondImage2x: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.timerId = setTimeout(this.startTimer, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId)
  }

  startTimer = () => {
    this.setState(state => ({activeScreen: state.activeScreen === 1 ? 2 : 1}))
    this.timerId = setTimeout(this.startTimer, 1600)
  }

  render() {
    const {title, firstImage, firstImage2x, secondImage, secondImage2x} = this.props
    const {activeScreen} = this.state
    return (
      <div className={cn('Screens', {'Screens_first': activeScreen === 1, 'Screens_second': activeScreen === 2})}>
        <img
          className="Screens-Image Screens-Image_first"
          width={649}
          height={1405}
          src={firstImage}
          srcSet={`${firstImage2x} 2x`}
          alt="Transformation"
        />
        <img
          className="Screens-Image Screens-Image_second"
          width={649}
          height={1405}
          src={secondImage}
          srcSet={`${secondImage2x} 2x`}
          alt={title}
        />
      </div>
    )
  }
}
