import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'

import './SubscriptionButtons.scss'

const monthlyUrl = 'https://strongherapp.com/discount?subscription=monthly'
const annualUrl = 'https://strongherapp.com/discount?subscription=annual'
const monthlyPrice = 14.99
const annualPrice = 89.99

const StyledFab = withStyles({
  root: {
    position: 'absolute',
    top: -12,
    right: -22,
    width: 42,
    height: 42,
    minHeight: 0,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 900,
    transform: 'rotate(-16deg)',
    color: 'white',
    textAlign: 'center',
    '@media (max-width: 959px)': {
      width: 30,
      height: 30,
      fontSize: 9,
      top: -9,
      right: -16,
    },
  },
})(Fab)

export default function SubscriptionButtons() {
  const monthlyDailyPrice = (monthlyPrice * 12 / 52 / 7).toFixed(2)
  const annualDailyPrice = (annualPrice / 52 / 7).toFixed(2)
  return (
    <div className="SubscriptionButtons">
      <div className="SubscriptionButtons-Block">
        <Button component="a" variant="contained" color="primary" href={monthlyUrl} target="_blank" rel="noopener noreferrer">
          ${monthlyDailyPrice} USD per day
        </Button>
        <p className="SubscriptionButtons-Text">${monthlyPrice} USD on a Monthly plan</p>
      </div>
      <div className="SubscriptionButtons-Block">
        <Button component="a" variant="contained" color="primary" href={annualUrl} target="_blank" rel="noopener noreferrer">
          {/* <span className="LightPeach">$0.49</span>&nbsp; */}
          ${annualDailyPrice} USD per day
        </Button>
        <StyledFab component="a" color="secondary" href={annualUrl} target="_blank" rel="noopener noreferrer">
          50% OFF
        </StyledFab>
        <p className="SubscriptionButtons-Text">${annualPrice} USD on an Annual plan</p>
      </div>
    </div>
  )
}
