import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

export default withStyles({
  root: {
    marginRight: 16,
    '@media (max-width: 959px)': {
      marginRight: 10
    },
    '@media (max-width: 599px)': {
      marginRight: 0,
      marginBottom: 14
    }
  }
})(Button)
