import React from 'react'
import PropTypes from 'prop-types'
import withWidth from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'

import melissaImage from '../assets/images/blocks/melissa-footer.png'
import melissaImage2x from '../assets/images/blocks/melissa-footer@2x.png'
import melissaTabletImage from '../assets/images/blocks/melissa-footer_tablet.png'
import melissaTabletImage2x from '../assets/images/blocks/melissa-footer_tablet@2x.png'
import melissaMobileImage from '../assets/images/blocks/melissa-footer_mobile.png'
import melissaMobileImage2x from '../assets/images/blocks/melissa-footer_mobile@2x.png'
import sevenGlyphsImage from '../assets/images/logos/7glyphs.png'
import ScrollAnimation from './_common/ScrollAnimation'
import socialLinks from '../data/social'
import './Footer.scss'

const blogLinks = [
  {id: 1, name: 'Food & Nutrition', href: 'https://strongherapp.com/blog'},
  {id: 2, name: 'Health & Beauty', href: 'https://strongherapp.com/blog'},
  {id: 3, name: 'Workouts & Fitness', href: 'https://strongherapp.com/blog'},
  {id: 4, name: 'Mental Health & Lifestyle', href: 'https://strongherapp.com/blog'},
]

const renderedSocialLinks = socialLinks.map(link => (
  <li key={link.id} className="Footer-SocialItem">
    <a className="Footer-SocialLink" aria-label={link.name} href={link.href} target="_blank" rel="noopener noreferrer">
      <link.Icon />
    </a>
  </li>
))

const renderedBlogLinks = blogLinks.map(link => (
  <li key={link.id}>
    <a className="Footer-BlogLink" href={link.href} target="_blank" rel="noopener noreferrer">
      {link.name}
    </a>
  </li>
))

Footer.propTypes = {
  width: PropTypes.string.isRequired,
}

function Footer({width}) {
  const isTablet = width === 'sm'
  const isMobile = width === 'xs'
  return (
    <footer className="Footer">
      <div className="Container">
        <Grid container spacing={2}>
          <Grid item xs={1} />
          <Grid item xs={12} sm={7}>
            <div className="Footer-Block">
              <ScrollAnimation className="Footer-Image" effect="fadeInLeft">
                <img
                  width={isTablet ? 147 : isMobile ? 144 : 209}
                  height={isTablet ? 241 : isMobile ? 301 : 344}
                  src={isTablet ? melissaTabletImage : isMobile ? melissaMobileImage : melissaImage}
                  srcSet={`${isTablet ? melissaTabletImage2x : isMobile ? melissaMobileImage2x : melissaImage2x} 2x`}
                  alt="Melissa Chalmers"
                />
              </ScrollAnimation>
              <p className="Footer-Attention">
                <b>
                  Melissa
                  {isMobile ? ' ' : <br />}
                  Chalmers
                </b>
              </p>
              <ul className="Footer-SocialList">{renderedSocialLinks}</ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="Footer-Blog">
              <h2 className="Footer-Title Title">Blog</h2>
              <ul>{renderedBlogLinks}</ul>
            </div>
          </Grid>
        </Grid>
      </div>
      <p className="Footer-Company">
        © The Vibe Fitness LTD<span className="Footer-CompanySpacer">|</span>
        <a className="Footer-DeveloperLink" href="https://7glyphs.com" target="_blank" rel="noopener noreferrer">
          <img alt="7 glyphs Logo" width={9} height={9} className="Footer-DeveloperLogo" src={sevenGlyphsImage} /> 7 glyphs
        </a>
      </p>
    </footer>
  )
}

export default withWidth()(Footer)
