import React, {memo} from 'react'

import ScrollAnimation from './_common/ScrollAnimation'
import './ApplicationEntry.scss'

function ApplicationEntry() {
  return (
    <div className="ApplicationEntry">
      <h2 className="Title Title_white">Strongher application</h2>
      <ScrollAnimation tag="p" className="ApplicationEntry-Attention Attention" effect="fadeInLeft">
        <b>
          Join Our 100,000
          <br />
          Strong Womens Community
        </b>
      </ScrollAnimation>
      <ScrollAnimation tag="p" className="ApplicationEntry-Text" effect="fadeInRight">
        This is your journey and we are here to support you along the way. Share your success and create awesome
        friendships with people on the same journey as you.
      </ScrollAnimation>
    </div>
  )
}

export default memo(ApplicationEntry)
