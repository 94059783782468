import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Plx from 'react-plx'
import withWidth from '@material-ui/core/withWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'

import {ReactComponent as FlameIcon} from '../assets/images/icons/flame.svg'
import {ReactComponent as WorkoutIcon} from '../assets/images/icons/workout.svg'
import {ReactComponent as AppleIcon} from '../assets/images/icons/apple.svg'
import {ReactComponent as CommunityIcon} from '../assets/images/icons/community.svg'
import {ReactComponent as NextIcon} from '../assets/images/icons/next.svg'
import transformationImage from '../assets/images/blocks/transformation.jpg'
import transformationImage2x from '../assets/images/blocks/transformation@2x.jpg'
import transformationSecondImage from '../assets/images/blocks/transformation-second.jpg'
import transformationSecondImage2x from '../assets/images/blocks/transformation-second@2x.jpg'
import foodImage from '../assets/images/blocks/food.jpg'
import foodImage2x from '../assets/images/blocks/food@2x.jpg'
import foodSecondImage from '../assets/images/blocks/food-second.jpg'
import foodSecondImage2x from '../assets/images/blocks/food-second@2x.jpg'
import communityImage from '../assets/images/blocks/community.jpg'
import communityImage2x from '../assets/images/blocks/community@2x.jpg'
import communitySecondImage from '../assets/images/blocks/community-second.jpg'
import communitySecondImage2x from '../assets/images/blocks/community-second@2x.jpg'
import workoutImage from '../assets/images/blocks/workout.jpg'
import workoutImage2x from '../assets/images/blocks/workout@2x.jpg'
import ScrollAnimation from './_common/ScrollAnimation'
import ApplicationEntry from './ApplicationEntry'
import Screens from './Screens'
import './Application.scss'

const pagination = [
  {id: 1, type: 'transformation', Icon: FlameIcon},
  {id: 2, type: 'workouts', Icon: WorkoutIcon},
  {id: 3, type: 'nutrition', Icon: AppleIcon},
  {id: 4, type: 'community', Icon: CommunityIcon},
]

const features = [
  {
    id: 1,
    type: pagination[0].type,
    title: 'Transformation results',
    description: 'Transformation results with our workout plan and nutritional guides.',
  },
  {
    id: 2,
    type: pagination[1].type,
    title: 'Workouts',
    description:
      'We have your week planned all you have to do is show up. Each exercise video comes with clear directions and real life examples on how to perform and get the most out of your exercise routine. Detailed descriptions and videos for each exercise.',
  },
  {
    id: 3,
    type: pagination[2].type,
    title: 'Nutrition Guide & Recipes',
    description:
      'Easy to follow customised food guide. Our unique point system helps you have flexibility with the foods you love while getting in shape.',
  },
  {
    id: 4,
    type: pagination[3].type,
    title: 'Community',
    description: 'Helpful community with discussions and themes. Join community like minded women on the same journey.',
  },
]

const styles = {
  iconButton: {
    color: 'rgba(255, 255, 255, 0.4)',
    transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    '@media (max-width: 959px)': {
      '& svg': {
        width: 26,
        height: 26,
      },
    },
  },
  activeIconButton: {
    color: 'white',
  },
  nextIconButton: {
    position: 'absolute',
    bottom: 280,
    right: -16,
    '@media (max-width: 1279px)': {
      bottom: 240,
      right: 'auto',
      left: '95%',
    },
    '@media (max-width: 959px)': {
      bottom: -24,
      left: 'calc(50% - 24px)',
    },
    '@media (max-width: 599px)': {
      position: 'relative',
      bottom: 'auto',
      left: 'auto',
    },
  },
}

class Application extends Component {
  state = {
    featureId: 1,
  }

  static propTypes = {
    width: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
  }

  handleFeatureIdChange = featureId => () => this.setState({featureId})

  handleFeatureIdNext = () => {
    this.setState(({featureId}) => ({
      featureId: featureId >= 4 ? 1 : featureId + 1,
    }))
  }

  get pagination() {
    const {classes} = this.props
    const {featureId} = this.state
    return pagination.map((button, index) => (
      <ScrollAnimation
        key={button.id}
        tag="li"
        className="Application-PaginationItem"
        effect="fadeInBottom"
        delay={index * 150}
      >
        <IconButton
          className={cn(classes.iconButton, {[classes.activeIconButton]: button.id === featureId})}
          onClick={this.handleFeatureIdChange(button.id)}
        >
          <button.Icon />
        </IconButton>
      </ScrollAnimation>
    ))
  }

  render() {
    const {width, classes} = this.props
    const {featureId} = this.state
    const parallaxData = [
      {
        start: '.Application-Actions',
        end: '.Workout',
        properties: [
          {
            startValue: 0,
            endValue: width === 'sm' || width === 'xs' ? -272 : -410,
            property: 'translateY',
          },
        ],
      },
    ]
    const currentFeature = features.find(feature => feature.id === featureId)
    const isFirstFeature = currentFeature.id === 1
    const isSecondFeature = currentFeature.id === 2
    const isThirdFeature = currentFeature.id === 3
    const isMobile = width === 'xs'
    const nextButton = (
      <IconButton className={classes.nextIconButton} onClick={this.handleFeatureIdNext}>
        <NextIcon />
      </IconButton>
    )
    return (
      <section className="Application">
        <div className="Application-Container Container">
          {isMobile && <ApplicationEntry />}
          <Grid container spacing={2} direction={isMobile ? 'column-reverse' : null}>
            <Hidden mdDown>
              <Grid item lg={1} />
            </Hidden>
            <Grid item xs={12} sm={6} lg={5}>
              {!isMobile && <ApplicationEntry />}
              <div className="Application-Actions">
                <ul className="Application-Pagination">{this.pagination}</ul>
                {isMobile && nextButton}
              </div>
              <div key={currentFeature.id} className="Application-Feature">
                <ScrollAnimation tag="h3" className="Application-Title" effect="fadeInLeft">
                  {currentFeature.title}
                </ScrollAnimation>
                <ScrollAnimation tag="p" className="Application-Text" effect="fadeInRight">
                  {currentFeature.description}
                </ScrollAnimation>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={5}>
              <div className="Application-Content">
                <ScrollAnimation className="Application-Phone" effect="scaleIn">
                  {isSecondFeature ? (
                    <div className="Application-Parallax">
                      <Plx parallaxData={parallaxData}>
                        <img
                          className="Application-ImageScroll"
                          width={649}
                          height={2199}
                          src={workoutImage}
                          srcSet={`${workoutImage2x} 2x`}
                          alt="Workouts"
                        />
                      </Plx>
                    </div>
                  ) : (
                    <Screens
                      title={currentFeature.title}
                      firstImage={isFirstFeature ? transformationImage : isThirdFeature ? foodImage : communityImage}
                      firstImage2x={
                        isFirstFeature ? transformationImage2x : isThirdFeature ? foodImage2x : communityImage2x
                      }
                      secondImage={
                        isFirstFeature
                          ? transformationSecondImage
                          : isThirdFeature
                          ? foodSecondImage
                          : communitySecondImage
                      }
                      secondImage2x={
                        isFirstFeature
                          ? transformationSecondImage2x
                          : isThirdFeature
                          ? foodSecondImage2x
                          : communitySecondImage2x
                      }
                    />
                  )}
                </ScrollAnimation>
                {!isMobile && nextButton}
              </div>
            </Grid>
            <Hidden mdDown>
              <Grid item lg={1} />
            </Hidden>
          </Grid>
        </div>
      </section>
    )
  }
}

export default withWidth()(withStyles(styles)(Application))
