import React, {Component} from 'react'

import Header from './Header'
import About from './About'
import Application from './Application'
import Workout from './Workout'
import Benefits from './Benefits'
import Footer from './Footer'

export default class App extends Component {
  componentDidMount() {
    window.addEventListener('load', this.handleLoaded)
  }

  handleLoaded = () => {
    document.getElementById('preloader').classList.remove('Preloader_show')
    document.body.style.overflow = 'auto'
  }

  render() {
    return (
      <>
        <Header />
        <main>
          <About />
          <Application />
          <Workout />
          <Benefits />
        </main>
        <Footer />
      </>
    )
  }
}
